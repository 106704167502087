<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{
                            operation != null ?
                                (operation === 'add' ? 'Add Room' : (operation === 'detail' ? 'Room Detail'
                                    : 'Edit Room')) : operationTitle
                        }}
                        <p><small>Manage Room List with Rates</small></p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/rooms/create">
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-if="getActiveProperty && $global.hasPermission('roomsstore')"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                        </b-input-group-append>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="rooms-table"
                                 v-if="$global.hasPermission('roomsview')">

                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length">
                                                <label>
                                                    Show
                                                    <b-form-select v-model="pagination.totalPerPage"
                                                                   class="datatable-select"
                                                                   :options="[10, 50, 100]"
                                                                   size="sm"></b-form-select>
                                                    entries
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div id="kt_datatable_filter" class="dataTables_filter">
                                                <label>
                                                    Search:
                                                    <b-form-input type="search"
                                                                  class="form-control form-control-sm ml-2 w-25"
                                                                  label="Search"
                                                                  v-on:keyup.enter="handleSearch"
                                                                  v-model="search"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <v-data-table
                                    responsive
                                    ref="table"
                                    :headers="columns"
                                    :items="dataSource"
                                    :loading="loading"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    hide-default-footer
                                    class="table table-head-custom table-vertical-center table-responsive"
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.s_gst="record">
                                        {{ record.item.s_gst }}%
                                    </template>
                                    <template v-slot:item.c_gst="record">
                                        {{ record.item.c_gst }}%
                                    </template>
                                    <template v-slot:item.rate="record">
                                        {{ $global.numberToLocationString(record.item.rate) }}
                                    </template>
                                    <template v-slot:item.action="record" class="action-column">
                                        <router-link :to="`rooms/edit/${record.item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               v-show="$global.hasPermission('roomsupdate')" href="javascript:;">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           v-show="$global.hasPermission('roomsdestroy')"
                                           @click="handleDeleteOperation(record.item.id)">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            v-model="pagination.current"
                                            :total-rows="pagination.total"
                                            :per-page="pagination.totalPerPage"
                                            size="lg"
                                        ></b-pagination>
                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>
            </div>
        </div>
    </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import defaultListMixin from "../../../assets/js/components/defaultList.mixin";
import {mapGetters, mapState} from "vuex";

export default {
    mixins: [defaultListMixin],
    components: {
        KTCard,
    },
    mounted() {
        this.getList(this.listQueryParams)
    },
    computed: {
        ...mapState([
            'global',
        ]),
        ...mapGetters({getActiveProperty: 'getActiveProperty'}),
    },
}
</script>
