import qs from "qs";
import {request} from "@/core/services/Request";

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Title",
        sortable: false,
        value: 'title',
    },
    {
        text: "Rate",
        sortable: false,
        value: 'rate',
    },
    {
        text: "SGST",
        sortable: false,
        value: 's_gst',
    },
    {
        text: "CGST",
        sortable: false,
        value: 'c_gst',
    },
    {
        text: "Action",
        value: "action",
        width: 150,
    },

];

const DEFAULT_FORM_STATE = {
    title: null,
    rate: null,
    c_gst: 0,
    s_gst: 0,
    _method: 'post',
};

export default {
    data() {
        const {query} = this.$route

        const listQueryParams = {
            ...query,
            page: (query.page && query.page > 0) ? parseInt(query.page) : 1,
            totalPerPage: (query.totalPerPage && query.totalPerPage > 0) ? query.totalPerPage : 10,
        }

        const pagination = {
            current: listQueryParams.page,
            totalPerPage: listQueryParams.totalPerPage,
            total: 0,
            last_page: 0,
        }

        return {
            operation: null,
            operationTitle: 'Rooms',
            listUrl: '/rooms',
            columns: COLUMN_DEFINATION(this),
            dataSource: [],
            loading: false,
            search: null,
            pagination:pagination,
            listQueryParams:listQueryParams,
        }
    },
    methods: {
        async getList(listQueryParams = false) {
            const params = {...this.filters, ...this.listQueryParams}

            try {
                const response = await request({
                    method: "get",
                    url: this.listUrl,
                    params: params,
                    paramsSerializer: ((params) => qs.stringify(params)),
                })

                const {data, meta} = response.data
                this.pagination = {
                    ...this.pagination,
                    current: parseInt(this.listQueryParams.page),
                    total: parseInt(meta.total),
                    last_page: parseInt(meta.last_page),
                }

                this.dataSource = data

                if (!this.dataSource.length) {
                    this.loading = false
                }

            } catch (error) {
                this.dataSource = []
            }
        },
        handleSearch() {
            this.listQueryParams = Object.assign({}, this.$route.query)
            if (this.search !== '') {
                this.listQueryParams['search'] = this.search
            } else {
                delete this.listQueryParams['search']
            }

            this.replaceQuery(this.listQueryParams)
        },
        replaceQuery(listQueryParams) {
            this.listQueryParams = listQueryParams;
            this.$router.replace({query: Object.assign({}, this.$route.query, listQueryParams)}).catch(() => {
            })
        },
    },
    watch: {
        'pagination.current': function (newVal) {
            const currentPage = (newVal ? newVal : 1)
            const listQueryParams = {
                ...this.listQueryParams,
                page: parseInt(currentPage)
            }

            this.replaceQuery(listQueryParams)
        },
        'pagination.totalPerPage': function (newVal) {
            const totalPerPage = (newVal ? newVal : 100)
            const listQueryParams = {
                ...this.listQueryParams,
                totalPerPage: parseInt(totalPerPage)
            }

            this.replaceQuery(listQueryParams)
        },
        '$route.query': function (to, from) {
            const {page} = to;
            to = {...to, page: (page ? page : 1)}
            this.getList(to)
        },
    }
}
